import React, { Component } from "react";

class App extends Component {
  componentDidMount() {
    window.VANTA.NET({
      color: 0x0080fb,
      backgroundColor: 0x010d27,
      el: 'body',
      points: 2.00,
      maxDistance: 5.00,
      spacing: 20.00
    })
  }
  render() {
    return (
      <div className='main'>
        <div class="logo-box"><div class="logo"></div></div>
          <h1>Coming soon!</h1>
          <h2 class="subtitle">You can contact me:</h2>
          <div class="social">
            <a href="mailto:p.buck.contact@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i></a>
            <a href="https://www.linkedin.com/in/philipp-buck-577820127/" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin"></i></a>
            <a href="https://github.com/philippbck" target="_blank" rel="noopener noreferrer"><i class="fab fa-github"></i></a>
          </div>
      </div>
    )
  } 
}

export default App;
